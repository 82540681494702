import * as React from "react";

import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";

import { LinkTitle } from "../link/LinkTitle";

export const ModelCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="About" multiline source="about" />
        <TextInput label="Avatar" source="avatar" />
        <NumberInput label="Favorited Count" source="favoritedCount" />
        <NumberInput label="Favorites Count" source="favoritesCount" />
        <TextInput label="Header" source="header" />
        <BooleanInput label="Is Performer" source="isPerformer" />
        <ReferenceArrayInput
          source="links"
          reference="Link"
          parse={(value: any) => value && value.map((v: any) => ({ id: v }))}
          format={(value: any) => value && value.map((v: any) => v.id)}
        >
          <SelectArrayInput optionText={LinkTitle} />
        </ReferenceArrayInput>
        <TextInput label="Location" source="location" />
        <NumberInput label="Medias Count" source="mediasCount" />
        <TextInput label="Name" source="name" />
        <TextInput label="OnlyFansID" source="onlyFansId" />
        <NumberInput label="Photos Count" source="photosCount" />
        <NumberInput label="Subscribe Price" source="subscribePrice" />
        <TextInput label="Username" source="username" />
        <NumberInput label="Videos Count" source="videosCount" />
        <TextInput label="Website" source="website" />
        <TextInput label="Wishlist" source="wishlist" />
      </SimpleForm>
    </Create>
  );
};
