import * as React from "react";
import {
  List,
  Datagrid,
  ListProps,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";
import Pagination from "../Components/Pagination";

export const ModelList = (props: ListProps): React.ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Models"}
      perPage={50}
      pagination={<Pagination />}
    >
      <Datagrid rowClick="show">
        <TextField label="About" source="about" />
        <TextField label="Avatar" source="avatar" />
        <DateField source="createdAt" label="Created At" />
        <TextField label="Favorited Count" source="favoritedCount" />
        <TextField label="Favorites Count" source="favoritesCount" />
        <TextField label="Header" source="header" />
        <TextField label="ID" source="id" />
        <BooleanField label="Is Performer" source="isPerformer" />
        <TextField label="Location" source="location" />
        <TextField label="Medias Count" source="mediasCount" />
        <TextField label="Name" source="name" />
        <TextField label="OnlyFansID" source="onlyFansId" />
        <TextField label="Photos Count" source="photosCount" />
        <TextField label="Subscribe Price" source="subscribePrice" />
        <DateField source="updatedAt" label="Updated At" />
        <TextField label="Username" source="username" />
        <TextField label="Videos Count" source="videosCount" />
        <TextField label="Website" source="website" />
        <TextField label="Wishlist" source="wishlist" />
      </Datagrid>
    </List>
  );
};
