import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ShowProps,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";

export const ArticleShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="Author" source="author" />
        <TextField label="Content" source="content" />
        <DateField source="createdAt" label="Created At" />
        <TextField label="ID" source="id" />
        <TextField label="Image URL" source="imageUrl" />
        <TextField label="KeyWord" source="keyWord" />
        <TextField label="Name" source="name" />
        <TextField label="Priority" source="priority" />
        <TextField label="slug" source="slug" />
        <BooleanField label="Status" source="status" />
        <BooleanField label="Top" source="top" />
        <DateField source="updatedAt" label="Updated At" />
      </SimpleShowLayout>
    </Show>
  );
};
