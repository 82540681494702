import * as React from "react";
import {
  Create,
  SimpleForm,
  CreateProps,
  TextInput,
  NumberInput,
  BooleanInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

export const ArticleCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Author" source="author" />
        <TextInput label="Image URL" source="imageUrl" />
        <TextInput label="KeyWord" source="keyWord" />
        <TextInput label="Name" source="name" />
        <NumberInput step={1} label="Priority" source="priority" />
        <TextInput label="slug" source="slug" />
        <BooleanInput label="Status" source="status" />
        <BooleanInput label="Top" source="top" />
        <RichTextInput source="content" />
      </SimpleForm>
    </Create>
  );
};
