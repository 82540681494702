import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  ShowProps,
  TextField,
  DateField,
  BooleanField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
} from "react-admin";

import { MODEL_TITLE_FIELD } from "./ModelTitle";

export const ModelShow = (props: ShowProps): React.ReactElement => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField label="About" source="about" />
        <TextField label="Avatar" source="avatar" />
        <DateField source="createdAt" label="Created At" />
        <TextField label="Favorited Count" source="favoritedCount" />
        <TextField label="Favorites Count" source="favoritesCount" />
        <TextField label="Header" source="header" />
        <TextField label="ID" source="id" />
        <BooleanField label="Is Performer" source="isPerformer" />
        <TextField label="Location" source="location" />
        <TextField label="Medias Count" source="mediasCount" />
        <TextField label="Name" source="name" />
        <TextField label="OnlyFansID" source="onlyFansId" />
        <TextField label="Photos Count" source="photosCount" />
        <TextField label="Subscribe Price" source="subscribePrice" />
        <DateField source="updatedAt" label="Updated At" />
        <TextField label="Username" source="username" />
        <TextField label="Videos Count" source="videosCount" />
        <TextField label="Website" source="website" />
        <TextField label="Wishlist" source="wishlist" />
        <ReferenceManyField reference="Link" target="ModelId" label="Links">
          <Datagrid rowClick="show">
            <DateField source="createdAt" label="Created At" />
            <TextField label="ID" source="id" />
            <TextField label="Link ID" source="linkId" />
            <TextField label="Link Type" source="linkType" />
            <TextField label="Link URL" source="linkUrl" />
            <ReferenceField label="Model" source="model.id" reference="Model">
              <TextField source={MODEL_TITLE_FIELD} />
            </ReferenceField>
            <DateField source="updatedAt" label="Updated At" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
