import * as React from "react";

import {
  Edit,
  SimpleForm,
  EditProps,
  NumberInput,
  SelectArrayInput,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import { ModelTitle } from "../model/ModelTitle";

export const LinkEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberInput step={1} label="Link ID" source="linkId" />
        <SelectArrayInput
          label="Link Type"
          source="linkType"
          choices={[
            { label: "TRIAL", value: "TRIAL" },
            { label: "TRACKING", value: "TRACKING" },
          ]}
          optionText="label"
          optionValue="value"
        />
        <TextInput label="Link URL" source="linkUrl" />
        <ReferenceInput source="model.id" reference="Model" label="Model">
          <SelectInput optionText={ModelTitle} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
