import * as React from "react";
import { Edit, SimpleForm, EditProps, TextInput } from "react-admin";

export const SessionEdit = (props: EditProps): React.ReactElement => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput label="cookie" source="cookie" />
        <TextInput label="userAgent" source="userAgent" />
        <TextInput label="userId" source="userId" />
        <TextInput label="xbc" source="xbc" />
      </SimpleForm>
    </Edit>
  );
};
